import { renderToStaticMarkup } from "react-dom/server"
import { notNull } from "@isbl/ts-utils"
import { head } from "~/models/s3.server"

type ImageMeta = {
  blurhash: string
  width: number
  height: number
  filename: string
}

export async function loadVariantImageData(
  alt: string,
  id: string,
  variant?: string,
) {
  if (!variant) {
    const parts = id.split("/")
    if (parts.length !== 2) return { url: id, alt }
    ;[id, variant] = parts
  }
  if (id.includes("/")) return { url: id, alt }

  if (variant !== "original") {
    let jpeg: ImageMeta | undefined = (
      await head("images", id + "/" + variant + ".jpeg")
    )?.meta as any
    let webp: ImageMeta | undefined = (
      await head("images", id + "/" + variant + ".webp")
    )?.meta as any
    let img = webp ?? jpeg
    if (img) {
      let avif: ImageMeta | undefined = (
        await head("images", id + "/" + variant + ".avif")
      )?.meta as any
      img = avif ?? img

      return {
        prefix: `/image/${id}/v/${variant}`,
        blurhash: img.blurhash,
        width: img.width,
        height: img.height,
        variants: [
          avif ? "avif" : null,
          webp ? "webp" : null,
          jpeg ? "jpeg" : null,
        ].filter(notNull),
        alt,
      }
    }
  }

  if (process.env.NODE_ENV !== "production") {
    if (variant !== "original") {
      return {
        prefix: `/image/${id}/v/${variant}`,
        blurhash: "",
        width: 0,
        height: 0,
        variants: ["jpeg"],
        alt,
      }
    }
  }

  return { url: `/image/${id}/v/original`, alt }
}

export type VariantImageData = Awaited<ReturnType<typeof loadVariantImageData>>

export function VariantImage({
  data,
  width,
  height,
  className,
}: {
  data: VariantImageData
  width?: number | string
  height?: number | string
  className?: string
}) {
  if (!data) return null
  if (typeof data === "string") data = JSON.parse(data)
  if ("url" in data) return <img src={data.url} alt={data.alt} />

  const inner =
    data.variants.length > 0 ? (
      data.variants.map((v, i, list) =>
        i === list.length - 1 ? (
          <img
            key={v}
            loading="lazy"
            src={`${data.prefix}.${v}`}
            alt={data.alt || " "}
            style={{ width: "100%", height: "100%" }}
          />
        ) : (
          <source key={v} srcSet={`${data.prefix}.${v}`} type={`image/${v}`} />
        ),
      )
    ) : (
      <img
        loading="lazy"
        src={data.prefix}
        alt={data.alt || " "}
        style={{ width: "100%", height: "100%" }}
      />
    )

  return (
    <picture
      className={className}
      data-blurhash={data.blurhash}
      style={{
        aspectRatio: `${data.width} / ${data.height}`,
        ...(width || height ? { width, height } : {}),
        display: "block",
        maxWidth: "100%",
      }}
    >
      {inner}
    </picture>
  )
}

export function OriginalImageLink({
  data,
  children,
  className,
}: {
  data: VariantImageData
  children: React.ReactNode
  className?: string
}) {
  if (!data) return null

  return (
    <a
      className={className}
      href={
        "url" in data
          ? data.url
          : data.prefix.replace(/\/v\/[^/]+$/, "/v/original")
      }
      target="_blank"
      rel="noreferrer"
    >
      {children}
    </a>
  )
}
